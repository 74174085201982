<template>
  <div class="w-100 p-l-30 p-r-30">
    <div v-if="
        !addNewPartyScreen &&
        !addNewBulkPartyScreen &&
        !batchRegistrationConfirmationScreen &&
        !batchRegistrationSuccessMsgScreen &&
        !tournamentListScreen
      ">
      <div v-if="deleteSuccess" class="alert alert-success" role="alert">
        大会管理者/関係者の情報が削除されました。
      </div>
      <div v-if="deleteFailed" class="alert alert-danger" role="alert">
        トーナメントマネージャー/関連情報は削除されていません。
      </div>
      <div class="row d-flex justify-content-between m-r-0">
        <div class="col select-tournament">
          <select v-model="tournamentId"
                  class="form-control color-33 select-style select-tournament form-select"
                  @change="getDataTournament">
            <option
                v-for="(tournament, tournamentId) in tournamentList"
                :key="'tournamentId'+ tournamentId"
                :value="tournament.tournamentId"
            >
              {{ tournament.tournamentName }}
            </option>
          </select>
          <div v-show="emptyTournament" class="form-error">表示期間内に大会はありません</div>
        </div>
        <div class="float-right width-150 scan-btn">
          <button class="btn btn-primary height-48 width-144 tournament-search-button" v-bind:disabled="tournamentId === 0"
                  @click="scanResultCTA">
            入場管理
          </button>
        </div>
      </div>
      <div class="row m-t-25 m-b-20">
        <div class="col-2 filtering-column140">
          <label for="roleId">ロール</label>
          <select id="roleId" v-model="roleId"
                  :required="true"
                  class="form-control color-33 mt-1 filter-select select-tournament form-select"
                  @change="getData">
            <option :key="Number('0')" :value="Number('0')">すべて</option>
            <option v-for="(role, roleIndex) in roles" :key="'roleIndex' + roleIndex" :value="role.roleId">
              {{ role.roleName }}
            </option>
          </select>
        </div>
        <div class="col-2 filtering-column140">
          <label for="accountStatus">アカウント状態</label>
          <select id="accountStatus" v-model="accountStatus"
                  class="form-control mt-1 color-33 select-tournament filter-select form-select"
                  @change="getData">
            <option v-for="(accountStatusName, accountStatusId) in accountStatuses"
                    :value="accountStatusId">
              {{ accountStatusName }}
            </option>
          </select>
        </div>
        <div class="col-4 filtering-column270">
          <label for="keyword">キーワード</label>
          <div class="input-group mt-1">
            <input
                id="keyword"
                v-model="keyword"
                class="form-control color-33 py-2 border-right-0 bg-transparent filter-search-form"
                placeholder="キーワードを入力"
                type="search"
                @keydown.enter="onKeyDown"
            />
            <span class="input-group-append">
              <button
                  class="input-group-text btn-search border-left-0 bg-transparent p-2"
                  type="submit"
                  @click="search"
              >
                <i class="fa fa-search"></i>
              </button>
            </span>
            <div v-show="errors['keyword']" class="form-error">{{ errors['keyword'] }}</div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="width-170">
          <button
              class="btn custom-button-outline fw-bold d-flex justify-content-around p-l-0"
              v-bind:disabled="tournamentId === 0"
              @click="addBulkTourAdminSip">
            <img src="../assets/images/ic_collective registration.svg"/>一括新規登録
          </button>
        </div>
        <div class="width-170 float-right">
          <button class="btn custom-button fw-bold" v-bind:disabled="tournamentId === 0" @click="addTourAdminsIp">
            <i class="fas fa-plus float-start"></i>新規登録
          </button>
        </div>
      </div>
      <div class="datatable">
        <Datatable
            :columns="columns"
            :getData="getData"
            :loading="loading"
            :response="response"
            :sortable="true"
            @cta="cta"
        >
        </Datatable>
      </div>
    </div>
    <div v-else-if="addNewBulkPartyScreen">
      <p>
        <a class="text-decoration-underline cursor-pointer"
           @click="backToTourAdminSip">大会管理者/関係者管理</a>
        <i class="fas fa-angle-right form-control-color"></i>関係者一括新規登録
      </p>
      <div class="card mt-3">
        <div class="card-header">
          <img src="../assets/images/sidebar/ic_menu_tournament_off.png"/>
          <span class="mx-3">{{ tournamentName }}</span>
        </div>
        <div class="card-body mt-5 mb-4">
          <div class="container text-center">
            <div>
              <button class="btn btn-primary height-44 width-350">
                <img
                    src="../assets/images/document-text.png"
                /><a class="text-decoration-none text-light" download
                     href="/download/batch_template_ip.xlsx">テンプレートファイルのダウンロード</a>
              </button>
            </div>
            <div class="input-group mt-5 width-720 mx-auto">
              <input
                  class="form-control selected-file"
                  placeholder="ファイル選択"
                  type="text"
              />
              <span class="input-group-btn"
              ><span class="btn btn-file"
              ><span class="fa fa-upload"></span>
                  <input ref="file"
                         accept=".xls, .xlsx"
                         type="file"
                         @change="fileSelected"/></span
              ></span>
            </div>
            <div class="mt-5 row button-container">
              <div class="col">
                <button class="btn cancel width-350" @click="backToTourAdminSip">キャンセル</button>
              </div>
              <div class="col">
                <button
                    :disabled="Object.keys(this.file).length === 0 && this.file.constructor === Object"
                    class="btn ok width-350"
                    @click="batchRegistrationConfirmation"
                >
                  登録
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="batchRegistrationConfirmationScreen">
      <p>
        <a
            class="text-decoration-underline cursor-pointer"
            @click="backToTourAdminSip"
        >大会管理者/関係者管理</a
        ><i class="fas fa-angle-right form-control-color"></i>関係者一括新規登録
      </p>
      <div class="card mt-3">
        <div class="card-header">
          <img src="../assets/images/sidebar/ic_menu_tournament_off.png"/>
          <span class="mx-3">{{ tournamentName }}</span>
        </div>
        <div class="card-body p-5">
          <div class="container text-center">
            <div>
              <p>一括登録を実行します。よろしいですか？</p>
            </div>
            <div class="row mt-5">
              <div class="col">
                <button class="btn cancel" @click="addBulkTourAdminSip">キャンセル</button>
              </div>
              <div class="col">
                <button class="btn ok" @click="submitFile">
                  登録
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="batchRegistrationSuccessMsgScreen">
      <p>
        <a
            class="text-decoration-underline cursor-pointer"
            @click="backToTourAdminSip"
        >大会管理者/関係者管理</a
        ><i class="fas fa-angle-right form-control-color"></i>関係者一括新規登録
      </p>
      <div class="card mt-3">
        <div class="card-header">
          <img src="../assets/images/sidebar/ic_menu_tournament_off.png"/>
          <span class="mx-3 font-16-medium"
          >{{ tournamentName }}</span
          >
        </div>
        <div class="card-body mx-auto">
          <div class="d-flex justify-content-center flex-column">
            <div v-for="mes in uploadResponseMessage">
              <p>{{ mes }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="tournamentListScreen">
      <p>
        <a
            class="text-decoration-underline cursor-pointer"
            @click="backToTourAdminSip"
        >大会管理者/関係者管理</a
        ><i class="fas fa-angle-right form-control-color"></i
      >大会管理者/関係者情報
      </p>
      <div class="d-flex justify-content-between mt-3">
        <div>
          <i class="fas fa-angle-left mx-2"></i>
          <a class="cursor-pointer" @click="prevUser"> {{ prevUserName }}{{
              prevUserId === null ? '' :
                  '(' + prevUserId + ')'
            }}</a>
        </div>

        <div>
          <a class="mx-2 cursor-pointer" @click="nextUser">{{ nextUserName }}{{
              nextUserId === null ? '' :
                  '(' + nextUserId + ')'
            }}</a>
          <i class="fas fa-angle-right"></i>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header">
          <img src="../assets/images/sidebar/ic_menu_tournament_off.png"/>
          <span class="mx-3">{{ tournamentName }}</span>
        </div>
        <div class="card-body px-5 mt-4 m-l-100 m-r-100">
          <div class="row mt-4">
            <div class="col">
              <labeltournamentId for="exampleInputPassword1">入場管理番号</labeltournamentId>
              <input
                  v-model="ipUser.user.userId"
                  class="form-control mt-2 full-width-input"
                  disabled
                  type="text"
              />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label for="lastName2">姓 名</label>
              <input id="lastName2"
                     v-model="ipUser.user.lastName"
                     class="form-control mt-2 half-width-input"
                     type="text"
              />
            </div>
            <div class="col">
              <label for="firstName2"></label>
              <input id="firstName2"
                     v-model="ipUser.user.firstName"
                     class="form-control mt-2 half-width-input"
                     type="text"
              />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">メールアドレス</label>
              <input
                  v-model="ipUser.user.email"
                  class="form-control mt-2 full-width-input"
                  type="email"
              />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">生年月日</label>
              <input
                  v-model="ipUser.user.birthday"
                  class="form-control mt-2 full-width-input"
                  type="text"
              />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label>ロール</label>
              <select
                  v-model="ipUser.userRoleId"
                  class="form-control select-role mt-2 select-style form-select"
              >
                <option
                    v-for="(role, index) in roles"
                    :key="index"
                    :value="role.roleId"
                >
                  {{ role.roleName }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">性別</label>
              <input
                  v-model="ipUser.user.gender"
                  class="form-control mt-2 full-width-input"
                  type="text"
              />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">国籍</label>
              <input
                  v-model="ipUser.user.nationalityName"
                  class="form-control mt-2 full-width-input"
                  type="text"
              />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">住所</label>
              <input
                  v-model="ipUser.user.address"
                  class="form-control mt-2 full-width-input"
                  type="text"
              />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">電話番号</label>
              <input
                  v-model="ipUser.user.phoneNo"
                  class="form-control mt-2 half-width-input"
                  type="text"
              />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">新型コロナワクチン接種</label>
              <input
                  v-model="coronaVaccinationStatus"
                  class="form-control mt-2 half-width-input"
                  type="text"
              />
            </div>
            <div class="col">
              <label for="exampleInputPassword1"></label>
              <input
                  v-model="ipUser.user.latestCoronaVaccinationDate"
                  :disabled="coronaVaccinationStatus === 'なし'"
                  class="form-control mt-2 half-width-input"
                  type="text"
              />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">PCR検査</label>
              <input
                  v-model="pcrTestResultStatus"
                  class="form-control mt-2 half-width-input"
                  type="text"
              />
            </div>
            <div class="col">
              <label for="exampleInputPassword1"></label>
              <input
                  v-model="ipUser.user.latestPcrTestDate"
                  :disabled="pcrTestResultStatus === '未検査'"
                  class="form-control mt-2 half-width-input"
                  type="text"
              />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col form-check p-l-35">
              <input
                  id="flexCheckDefault"
                  v-model="ipUser.pastAnswersRequired"
                  class="form-check-input"
                  type="checkbox"
                  value=""
              />
              <label class="form-check-label" for="flexCheckDefault">
                過去14日間における回答をさせる
              </label>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-6">
              <Modal :admin-user-delete="deleteUser"
                     :back-to-root="backToTourAdminSip"
                     :data="ipUser"
                     :reload-data="getData"
                     body="  を大会から削除してもよろしいですか？ "
                     button-title="削除"
                     cancel-title="キャンセル"
                     ok-title="削除" title="削除"></Modal>
            </div>
            <button class="col-3 btn no" @click="backToTourAdminSip">
              キャンセル
            </button>
            <button class="col-3 btn yes" @click="updateUser">保存</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {apiConfig, app, datatable, gender, defaults} from "@/constants";
import Datatable from "../components/datatable/Datatable";
import {mapGetters} from "vuex";
import {AdminUploadResult} from "../assets/jsadminclient";
import Common from "../assets/jsadminclient/common";
import Helper from "../assets/jsadminclient/helper";
import Modal from '../components/modal/Modal'

export default {
  name: "TourAdminsIp",
  components: {Datatable, Modal},
  props: {
    defaultResponse: {type: Object, default: null}
  },
  data() {
    return {
      app: app,
      apiConfig: apiConfig,
      adminType: defaults.adminType,
      adminTypes: defaults.adminTypes,
      accountStatus: defaults.accountStatus,
      accountStatuses: defaults.accountStatuses,
      response: {
        sort: 'userId',
        order: 'desc',
        current_page: 1,
        per_page: datatable.default.perPage
      },
      keyword: '',
      deleteSuccess: false,
      deleteFailed: false,
      gender: gender,
      loading: true,
      emptyTournament: false,
      addNewPartyScreen: false,
      addNewBulkPartyScreen: false,
      batchRegistrationConfirmationScreen: false,
      batchRegistrationSuccessMsgScreen: false,
      tournamentListScreen: false,
      columns: datatable.columns.touradminsip,
      tournamentId: 0,
      tournamentList: {},
      file: {},
      tournamentName: '',
      ipUserId: "",
      nextUserId: '',
      nextUserName: '',
      prevUserId: '',
      prevUserName: '',
      coronaVaccinationStatus: "",
      pcrTestResultStatus: '',
      ipUser: {},
      roles: {},
      roleId: defaults.roleId,
      user: {},
      reloadKey: 1,
      year: "",
      month: "",
      date: "",
      uploadResponseMessage: [],
      errors: []
    };
  },
  computed: {
    ...mapGetters(["config"]),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
          {length: year - 1919},
          (value, index) => 1919 + (index + 1)
      )
    },
  },
  mounted() {
    this.restoreSearchQuery()
    this.initialPageLoadHandler();
    this.loadTournamentList()
  },
  methods: {
    onKeyDown(event) {
      if (event.keyCode !== 13) {
        return
      }
      this.search()
    },
    initialPageLoadHandler() {
      if (this.defaultResponse) {
        this.response = this.defaultResponse
      }
      Object.assign(this.columns[4], {
        format: (organization) => {
          return organization
        },
      });
      
      Object.assign(this.columns[6], {
        format: (details) => {
          return '<i class="fas fa-info-circle font-16 text-info"></i>';
        },
      });
      this.getRoles()
    },
    search() {
      this.errors = []
      this.response.current_page = 1
      this.getData();
    },
    addTourAdminsIp() {
      this.$router.push({
        name: 'TourAdminsIpRegistration',
        params: {
          tournamentId: Number(this.tournamentId),
        },
        query: this.createSearchQuery()
      })
    },
    addBulkTourAdminSip() {
      this.$router.push({
        name: 'TourAdminsIpBatch',
        params: {tournamentId: Number(this.tournamentId)},
        query: this.createSearchQuery()
      })
    },
    backToTourAdminSip() {
      this.batchRegistrationConfirmationScreen = false;
      this.batchRegistrationSuccessMsgScreen = false;
      this.tournamentListScreen = false;
      this.$router.push({name: "TourAdminSip"});
    },
    batchRegistrationConfirmation() {
      this.batchRegistrationConfirmationScreen = true;
      this.batchRegistrationSuccessMsgScreen = false;
      this.addNewBulkPartyScreen = false;
    },
    batchRegistrationSuccessMsg() {
      this.batchRegistrationSuccessMsgScreen = true;
      this.addNewBulkPartyScreen = false;
      this.batchRegistrationConfirmationScreen = false;
    },
    tournaments() {
      this.tournamentListScreen = true;
      this.batchRegistrationSuccessMsgScreen = false;
      this.addNewBulkPartyScreen = false;
      this.batchRegistrationConfirmationScreen = false;
    },
    fileSelected() {
      this.file = this.$refs.file.files[0];
      $(".selected-file").val(this.$refs.file.files[0].name)
    },
    async submitFile() {
      try {
        const api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsTournamentIdIpUsersUploadPostAsync(
            this.tournamentId,
            this.file
        );
        if (result) {
          this.uploadResponseMessage.splice(0, this.uploadResponseMessage.length)

          let res = AdminUploadResult.createFromJson(result)
          let mes = res.successCount + "件　登録しました。"
          if (res.updated) {
            this.uploadResponseMessage.push(mes)
          }

          if (res.failureCount > 0) {
            mes = res.failureCount + "件　エラーになりました。"
            this.uploadResponseMessage.push(mes)
          }

          res.failureReasons.forEach((item) => {
            this.uploadResponseMessage.push(item)
          })
          this.batchRegistrationSuccessMsg();
        }
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getDataTournament() {
      Common.saveSelectTournamentIdCommon(this.tournamentId)
      await this.getData()
    },
    async getData() {
      try {
        if (this.emptyTournament) {
          this.loading = false
          this.response.data = [];
          return
        }
        this.loading = true
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token))
        var result = await api.tournamentsTournamentIdIpUsersGetAsync(
            this.tournamentId,
            this.roleId,
            this.accountStatus,
            this.keyword,
            this.response.sort,
            this.response.order,
            this.response.current_page,
            this.response.per_page
        )

        if (result) {
          this.loading = false
          // this.reloadKey += 1
          this.response = Helper.formatResponse(result)
          // map user data
          this.response.data = result.items.map(function (item) {
            return {
              userId: item.user.userId,
              lastName: item.user.lastName,
              firstName: item.user.firstName,
              email: item.user.email,
              userRoleName: item.userRoleName,
              organization: item.organization
            }
          })

          Common.updateCurrentPageQuery(this, this.createSearchQuery())
        }
      } catch (e) {
        this.response.data = [];
        this.loading = false;
        this.errors = Common.getErrorList(e.response, this.errors)

        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async createUser() {
      try {
        this.errors = [];
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        this.user.birthday = this.year + "/" + this.month + "/" + this.date;
        var result = await api.tournamentsTournamentIdIpUsersPostAsync(
            this.tournamentId,
            this.user
        );
        this.backToTourAdminSip();
        this.getData();
      } catch (e) {
        this.loading = false;
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async updateUser() {
      try {
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        if (this.coronaVaccinationStatus === "Yes") {
          this.ipUser.user.hasCoronaVaccination = true;
        } else {
          this.ipUser.user.hasCoronaVaccination = false;
        }
        var result = await api.tournamentsTournamentIdIpUsersIpUserIdPostAsync(
            this.tournamentId,
            this.ipUserId,
            this.ipUser
        );
        this.backToTourAdminSip();
        this.getData();
      } catch (e) {
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async deleteUser() {
      try {
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsTournamentIdIpUsersIpUserIdDeleteAsync(
            this.tournamentId,
            this.ipUserId,
            this.ipUser
        );
        this.deleteSuccess = true
        // this.backToTourAdminSip();
        this.getData()
      } catch (e) {
        this.loading = false;
        this.deleteFailed = true
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    cta(key, row) {
      const ipUserId = row[key];
      this.tournaments()
      this.$router.push({
        name: 'TourAdminsIpUserDetail',
        params: {
          tournamentId: Number(this.tournamentId),
          userId: ipUserId
        },
        query: this.createSearchQuery()
      })
    },
    async getUser() {
      try {
        const api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        this.ipUser = await api.tournamentsTournamentIdIpUsersIpUserIdGetAsync(
            this.tournamentId,
            this.ipUserId
        );
        this.nextUserId = this.ipUser.nextUserId
        this.nextUserName = this.ipUser.nextUserName
        this.prevUserId = this.ipUser.prevUserId
        this.prevUserName = this.ipUser.prevUserName

        if (this.ipUser.user.hasCoronaVaccination) {
          this.coronaVaccinationStatus = "あり (2回目)";
        } else {
          this.coronaVaccinationStatus = "なし";
        }
        if (this.ipUser.user.latestPcrTestResult) {
          this.pcrTestResultStatus = '陽性'
        } else if (!this.ipUser.user.latestPcrTestResult) {
          this.pcrTestResultStatus = '陰性'
        } else if (this.ipUser.user.latestPcrTestResult === null) {
          this.pcrTestResultStatus = '未検査'
        }
      } catch (e) {
        this.ipUser = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getRoles() {
      try {
        this.loading = true
        const api = new AdminApi.SystemApi(Helper.apiConfig(this.config.token));
        var result = await api.systemRolesGetAsync();
        this.roles = result.items;
      } catch (e) {
        this.roles = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    scanResultCTA() {
      this.$router.push({name: 'Scan', params: {tournamentId: this.tournamentId}})
    },
    async loadTournamentList() {
      try {
        this.loading = true
        const start = Common.getHomeStartDate()
        const end = Common.getHomeEndDate()
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token))
        var result = await api.tournamentsListGetAsync(start, end)
        if (result.items) {
          this.loading = false
          this.tournamentList = result.items
          const savedId = this.tournamentId > 0 ? this.tournamentId : Common.getSelectTournamentIdCommon()
          if (savedId !== 0 && this.tournamentList.find((f) => f.tournamentId === savedId)) {
            this.tournamentId = savedId
          } else {
            if (this.tournamentList.length > 0) {
              this.tournamentId = Number(this.tournamentList[0].tournamentId)
            } else {
              this.emptyTournament = true
            }
          }

          this.getData()
        }
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getTournament() {
      try {
        this.loading = true
        const api = new AdminApi.JTATournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.jtaTournamentsTournamentIdGetAsync(
            this.tournamentId,
        );
        this.tournamentName = result.tournamentName
      } catch (e) {
        this.tournamentName = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    createSearchQuery(orgQuery = null) {
      return Common.appendSearchQuery(
          orgQuery,
          {
            role: this.roleId,
            accountStatus: this.accountStatus,
            keyword: this.keyword,
            sort: this.response.sort,
            order: this.response.order,
            currentPage: this.response.current_page,
            perPage: this.response.per_page,
            tournament: this.tournamentId
          })
    },
    restoreSearchQuery() {
      Common.restoreQuery(this, 'tournamentId', Common.getNumberOrNull(this.$route.query.tournament))
      Common.restoreQuery(this, 'roleId', this.$route.query.role)
      Common.restoreQuery(this, 'accountStatus', this.$route.query.accountStatus)
      Common.restoreQuery(this, 'keyword', this.$route.query.keyword)
      Common.restoreQuery(this.response, 'sort', this.$route.query.sort)
      Common.restoreQuery(this.response, 'order', this.$route.query.order)
      Common.restoreQuery(this.response, 'per_page', this.$route.query.perPage)
      Common.restoreQuery(this.response, 'current_page', this.$route.query.currentPage)
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  font-size: 16px;
}

.delete {
  width: 160px;
  height: 44px;
}

.select-role.form-control {
  background: #daced1;
  color: #888888;
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.select-tournament.form-control {
  background: #f2ecf0;
  color: #888888;
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.form-check {
  padding-left: 33px;
}

.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #f2ecf0;
  height: 48px;
  line-height: 30px;
  border-radius: 0px;
}

.fa-upload {
  color: #f2ecf0;
}

.button-container {
  padding: 0px 190px;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control {
  background: #f2ecf0;
  border: none;
}

::placeholder {
  color: #333333;
}

.custom-button {
  background: #c95d6c;
  color: #ffffff;
  border-radius: 4px;
  width: 160px;
}

.custom-button-outline.btn {
  background: #ffffff;
  color: #c95d6c;
  border: 1px solid #c95d6c;
  border-radius: 4px;
  width: 160px;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

.yes {
  width: 225px;
  height: 44px;
  color: white;
  background-color: #c95d6c;
}

.no {
  width: 225px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #ffffff;
}

.ok {
  width: 414px;
  height: 44px;
  color: white;
  background-color: #c95d6c;
}

.ok:active {
  color: white;
  background-color: #c95d6c;
}

.cancel {
  width: 414px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #ffffff;
}

.cancel:active {
  color: #c95d6c;
  background-color: #ffffff;
}

a {
  color: #333333;
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #f8f8f8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #f0e7ed;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0px !important;
  }
}

.filter-select,
.filter-search-form,
.btn-search {
  font-size: 13px;
  height: 32px !important;
  line-height: 13px;
}

.filter-search-form {
  border: 1px solid #dee2e6;
}

.btn-search {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.fas {
  &.fa-plus {
    margin-top: 0.29rem !important;
  }
}

.content {
  &.touradminsip {
    .custom-button {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .custom-button-outline.btn {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .tournamentId_box {
      width: calc(100% - 160px);
    }

    .filtering-column140 {
      min-width: 140px;
    }

    .filtering-column270 {
      min-width: 270px;
      max-width: 300px;
    }
  }
}
</style>

<style lang="scss">
.touradminsip table {
  display: table;

  tr {
    th,
    td {
      &:nth-child(1), &:nth-child(2) {
        width: 130px;
      }

      &:nth-child(6) {
        width: 290px;
      }

      //&:nth-child(7) {
      //  width: 50px;
      //}

      @media only screen and (max-width: 1200px) {
        &:nth-child(1) {
          width: 123px;
        }
        &:nth-child(2), &:nth-child(3) {
          width: 133px;
        }

      }
      @media only screen and (max-width: 1100px) {
        &:nth-child(5) {
          width: 58px;
        }
      }
      @media only screen and (max-width: 1030px) {
        &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
          width: auto;
        }
      }
      @media only screen and (max-width: 994px) {
        //&:nth-child(2), &:nth-child(3) {
        //  width: 133px;
        //}
        //&:nth-child(5) {
        //  width: 58px;
        //}
      }
    }
  }
}
</style>