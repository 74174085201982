import { render, staticRenderFns } from "./TourAdminsIp.vue?vue&type=template&id=38a611e2&scoped=true&"
import script from "./TourAdminsIp.vue?vue&type=script&lang=js&"
export * from "./TourAdminsIp.vue?vue&type=script&lang=js&"
import style0 from "./TourAdminsIp.vue?vue&type=style&index=0&id=38a611e2&lang=scss&scoped=true&"
import style1 from "./TourAdminsIp.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a611e2",
  null
  
)

export default component.exports