<template>
  <div>
    <b-button v-b-modal.modal-prevent-closing>{{ buttonTitle }}</b-button>
    <b-modal
        id="modal-prevent-closing"
        ref="modal"
        :cancel-title="cancelTitle"
        :ok-title="okTitle"
        :title="title"
        centered
        @hidden="resetModal"
        @ok="handleOk"
        @show="unstickNavbar">
      <div v-if="parent === 'UserRecordDetail'">
        {{ data.lastName }} {{ data.firstName }} {{ body }}<br>{{ confirmationMsg }}
      </div>
      <div v-else-if="parent === 'UserDetail'">
        {{ data.lastName }} {{ data.firstName }} {{ body }}<br><br>{{ confirmationMsg }}<br>{{ confirmationMsg2 }}
      </div>
      <div v-else-if="parent === 'TournamentDetail'">
        {{ data.tournamentName }} {{ body }}
      </div>
      <div v-else-if="parent === 'HealthRecord'">
        {{ data.lastName }} {{ data.firstName }} {{ body }} <br>{{ confirmationMsg }}
      </div>
      <div v-else-if="parent === 'TourAdminsIpUserDetail'">
        {{ data.user.lastName }} {{ data.user.firstName }} {{ body }}
      </div>
      <div v-else-if="parent === 'ReminderDetail'">
        {{ data.reminderTitle }} {{ confirmationMsg }}
      </div>
      <div v-if="isCheckbox">
        <div class="form-check ml-2 mt-4 mx-auto">
          <input id="flexCheckDefault" v-model="allCheck" class="form-check-input m-t-5" type="checkbox"
                 @click="toggleCheckBox">
          <label class="form-check-label" for="flexCheckDefault">
            {{ checkboxTitle }}
          </label>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ['buttonTitle', 'title', 'okTitle', 'data', 'cancelTitle', 'body', 'reloadData',
    'tournamentDelete', 'userDelete','reminderDelete', 'backToRoot', 'adminUserDelete', 'confirmationMsg', 'confirmationMsg2', 'isCheckbox', 'checkboxTitle', 'userRecordApproval'],
  data() {
    return {
      allCheck: false,
      parent: '',
    }
  },
  beforeUpdate() {
    if (this.$route.name === 'UserRecordDetail') {
      if(!this.data.approval) {
        $(".btn-secondary").removeClass('btn-approve btn-deauthorize').addClass('btn-approve').text("承認")
          $(".modal-footer").find('.btn-secondary').text("キャンセル")
      }
      else if(this.data.approval) {
        $(".btn-secondary").removeClass('btn-approve btn-deauthorize').addClass('btn-deauthorize').text("承認解除")
          $(".modal-footer").find('.btn-secondary').text("キャンセル")
      }
    }
  },
  mounted() {
    if (this.$route.name === 'UserRecordDetail') {
      if(!this.data.approval) {
        $(".btn-secondary").removeClass('btn-approve btn-deauthorize').addClass('btn-approve').text("承認")
          $(".modal-footer").find('.btn-secondary').text("キャンセル")
      }
      else if(this.data.approval) {
        $(".btn-secondary").removeClass('btn-approve btn-deauthorize').addClass('btn-deauthorize').text("承認解除")
          $(".modal-footer").find('.btn-secondary').text("キャンセル")
      }
    }
  },
  methods: {
    resetModal() {
      this.stickNavbar()
    },
    unstickNavbar() {
      $(".navbar").removeClass("sticky");
      if (this.$parent.$vnode.tag.split('-').pop() === 'UserDetail') {
        this.parent = 'UserDetail'
      } else if (this.$parent.$vnode.tag.split('-').pop() === 'TournamentDetail') {
        this.parent = "TournamentDetail"
      } else if (this.$parent.$vnode.tag.split('-').pop() === 'UserRecordDetail') {
        this.parent = "UserRecordDetail"
      } else if (this.$parent.$vnode.tag.split('-').pop() === 'HealthRecord') {
        this.parent = "HealthRecord"
      } else if (this.$parent.$vnode.tag.split('-').pop() === 'TourAdminsIpUserDetail') {
        this.parent = "TourAdminsIpUserDetail"
      } else if (this.$parent.$vnode.tag.split('-').pop() === 'ReminderDetail') {
        this.parent = "ReminderDetail"
      }
    },
    stickNavbar() {
      $(".navbar").addClass("sticky");
    },
    toggleCheckBox() {
      this.allCheck = !this.allCheck
      this.$emit('getAllCheck', this.allCheck)
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit(this.parent)
    },
    handleSubmit(parent) {
      // Hide the modal manually
      if (parent === 'UserDetail') {
        this.userDelete()
      } else if (parent === 'TournamentDetail') {
        this.tournamentDelete()
      } else if (parent === 'UserRecordDetail') {
        this.userRecordApproval();
      } else if (parent === 'TourAdminsIpUserDetail') {
        this.adminUserDelete();
      } else if (parent === 'ReminderDetail') {
        this.reminderDelete();
      }
      this.$nextTick(() => {
        if (this.backToRoot) {
          this.backToRoot()
        }
        // if (this.reloadData) {
        //   this.reloadData()
        // }
        this.$bvModal.hide('modal-prevent-closing')
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.btn {
  &-secondary {
    width: 100%;
    background-color: #212529;
    border-color: #212529;
  }
  &-approve {
    height: 44px;
    &, &:focus {
      &, &:hover, &:active {
        background-color: #c95d6c !important;
        color: #FFF !important;
        border-color: #c95d6c !important;
      }
    }
  }
  &-deauthorize {
    height: 44px;
    &, &:focus {
      &, &:active {
        background-color: #FFF !important;
        color: #c95d6c !important;
        border-color: #c95d6c !important;
      }
    }
    &:hover{
      background-color: #c95d6c !important;
      color: #FFF !important;
      border-color: #c95d6c !important;
    }
  }
}
</style>
